<script>
import { get } from 'vuex-pathify'

export default {
	lang: 'account',
	data() {
		return {
			loading: false,
			items: [],
		}
	},
	trackPageView: true,
	computed: {
		wishlistIds: get('shop/wishlistIds'),
		wishlistItems() {
			return this.items.filter((item) => this.wishlistIds.includes(item.id))
		},
		noItems() {
			return this.wishlistItems.length < 1 && this.loading === false
		},
	},
	created() {
		this.$shopApi.get({
			url: '/catalog/wishlist/products',
			loading: (v) => (this.loading = v),
			onSuccess: ({ data }) => {
				this.items = data.products
			},
		})
	},
}
</script>

<template>
	<UserpanelLayout :title="$lang('Mis Favoritos')">
		<div v-if="noItems" class="text-center py-16">
			<v-icon x-large color="grey lighten-1">mdi-heart-broken</v-icon>
			<div class="font-weight-bold text-h5 mb-4">
				{{ 'No tienes nada en favoritos' | lang }}
			</div>
			<div class="mb-6">{{ 'Para comenzar a añadir puedes...' | lang }}</div>
			<div>
				<Button class="primary" x-large @click="$shop.goShop()">
					{{ 'Ir a la Tienda' | lang }}
				</Button>
			</div>
		</div>
		<ProductsLayout :loading="loading" :products="wishlistItems" />
	</UserpanelLayout>
</template>

<style></style>
